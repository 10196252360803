@charset "UTF-8";
/*ディスプレイ初期値設定*/
/*以下、サイト固有の設定*/
/*
$gray_color01: #D1D1D1;
$gray_color02: #ccc;
$gray_color03: #777;
$gray_color04: #555;
$gray_color05: #F4F3F3;
$gray_color06: #ddd;
*/
/*サイト共通の間隔*/
/*英語用font読み込み指定*/
/*
@font-face {
	font-family: 'General Sans';
	src: url("../font/GeneralSans-Regular.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
	font-family: 'General Sans';
	src: url("../font/GeneralSans-Semibold.otf") format("otf");
    font-weight: bold;
    font-style: normal;
}
*/
/* CSS Document */
.word_break_all {
  word-break: break-all !important;
}

.float_left {
  float: left !important;
}

.float_right {
  float: right !important;
}

.clear_both {
  clear: both !important;
}

/*　ボーダー関連　*/
.border_none {
  border: none !important;
}

.border_gray {
  border: 1px solid #ddd !important;
}

.border_top_gray {
  border-top: 1px solid #ddd !important;
}

.border_left_gray {
  border-left: 1px solid #ddd !important;
}

.border_right_gray {
  border-right: 1px solid #ddd !important;
}

.border_bottom_gray {
  border-bottom: 1px solid #ddd !important;
}

/*　文字色　*/
.color_black {
  color: #222 !important;
}

.color_gray {
  color: #ddd !important;
}

.color_white {
  color: #fff !important;
}

.color_red {
  color: #FF0000 !important;
}

.color_green {
  color: #478393 !important;
}

.color_yellow {
  color: #FFF000 !important;
}

/*　文字装飾　*/
.text_deco_underline {
  text-decoration: underline !important;
}

/*　display関連　*/
.display_block {
  display: block !important;
}

.display_none {
  display: none !important;
}

.display_inlineblock {
  display: inline-block !important;
}

/*flex関連　*/
.display_flex {
  display: flex !important;
}

.justify_start {
  justify-content: flex-start !important;
}

.justify_center {
  justify-content: center !important;
}

.justify_end {
  justify-content: flex-end !important;
}

.justify_around {
  justify-content: space-around !important;
}

.justify_between {
  justify-content: space-between !important;
}

.align_items_start {
  align-items: flex-start !important;
}

.align_items_center {
  align-items: center !important;
}

.align_items_end {
  align-items: flex-end !important;
}

.flex_wrap_wrap {
  flex-wrap: wrap !important;
}

.flex_wrap_nowrap {
  flex-wrap: nowrap !important;
}

/*　その他　*/
.pointer_none {
  pointer-events: none !important;
}

.overflow_hidden {
  overflow: hidden !important;
}

.overflow_auto {
  overflow: auto !important;
}

.overflow_xscroll {
  overflow-x: scroll !important;
}

.white_space_nowrap {
  white-space: nowrap !important;
}

.white_space_normal {
  white-space: normal !important;
}

.align_item_flex_start {
  align-items: flex-start !important;
}

.align_item_center {
  align-items: center !important;
}

.align_item_flex_end {
  align-items: flex-end !important;
}

/*　position　*/
.position_static {
  position: static !important;
}

.position_relative {
  position: relative !important;
}

.position_absolute {
  position: absolute !important;
}

.position_fixed {
  position: fixed !important;
}

/*　margin padding　*/
.ma_0 {
  margin: 0 !important;
}

.ma_5px {
  margin: 5px !important;
}

.ma_10px {
  margin: 10px !important;
}

.ma_15px {
  margin: 15px !important;
}

.ma_20px {
  margin: 20px !important;
}

.ma_25px {
  margin: 25px !important;
}

.ma_30px {
  margin: 30px !important;
}

.ma_45px {
  margin: 45px !important;
}

.ma_50px {
  margin: 50px !important;
}

.ma_t_0 {
  margin-top: 0 !important;
}

.pd_t_0 {
  padding-top: 0 !important;
}

.ma_t_5px {
  margin-top: 5px !important;
}

.pd_t_5px {
  padding-top: 5px !important;
}

.ma_t_10px {
  margin-top: 10px !important;
}

.pd_t_10px {
  padding-top: 10px !important;
}

.ma_t_15px {
  margin-top: 15px !important;
}

.pd_t_15px {
  padding-top: 15px !important;
}

.ma_t_20px {
  margin-top: 20px !important;
}

.pd_t_20px {
  padding-top: 20px !important;
}

.ma_t_25px {
  margin-top: 25px !important;
}

.pd_t_25px {
  padding-top: 25px !important;
}

.ma_t_30px {
  margin-top: 30px !important;
}

.pd_t_30px {
  padding-top: 30px !important;
}

.ma_t_45px {
  margin-top: 45px !important;
}

.pd_t_45px {
  padding-top: 45px !important;
}

.ma_t_50px {
  margin-top: 50px !important;
}

.pd_t_50px {
  padding-top: 50px !important;
}

.ma_t_1em {
  margin-top: 1em !important;
}

.ma_t_1rem {
  margin-top: 1rem !important;
}

.pd_t_1em {
  padding-top: 1em !important;
}

.pd_t_1rem {
  padding-top: 1rem !important;
}

.ma_t_2em {
  margin-top: 2em !important;
}

.ma_t_2rem {
  margin-top: 2rem !important;
}

.pd_t_2em {
  padding-top: 2em !important;
}

.pd_t_2rem {
  padding-top: 2rem !important;
}

.ma_t_3em {
  margin-top: 3em !important;
}

.ma_t_3rem {
  margin-top: 3rem !important;
}

.pd_t_3em {
  padding-top: 3em !important;
}

.pd_t_3rem {
  padding-top: 3rem !important;
}

.ma_t_4em {
  margin-top: 4em !important;
}

.ma_t_4rem {
  margin-top: 4rem !important;
}

.pd_t_4em {
  padding-top: 4em !important;
}

.pd_t_4rem {
  padding-top: 4rem !important;
}

.ma_t_5em {
  margin-top: 5em !important;
}

.ma_t_5rem {
  margin-top: 5rem !important;
}

.pd_t_5em {
  padding-top: 5em !important;
}

.pd_t_5rem {
  padding-top: 5rem !important;
}

.ma_t_6em {
  margin-top: 6em !important;
}

.ma_t_6rem {
  margin-top: 6rem !important;
}

.pd_t_6em {
  padding-top: 6em !important;
}

.pd_t_6rem {
  padding-top: 6rem !important;
}

.ma_t_7em {
  margin-top: 7em !important;
}

.ma_t_7rem {
  margin-top: 7rem !important;
}

.pd_t_7em {
  padding-top: 7em !important;
}

.pd_t_7rem {
  padding-top: 7rem !important;
}

.ma_t_8em {
  margin-top: 8em !important;
}

.ma_t_8rem {
  margin-top: 8rem !important;
}

.pd_t_8em {
  padding-top: 8em !important;
}

.pd_t_8rem {
  padding-top: 8rem !important;
}

.ma_t_9em {
  margin-top: 9em !important;
}

.ma_t_9rem {
  margin-top: 9rem !important;
}

.pd_t_9em {
  padding-top: 9em !important;
}

.pd_t_9rem {
  padding-top: 9rem !important;
}

.ma_t_10em {
  margin-top: 10em !important;
}

.ma_t_10rem {
  margin-top: 10rem !important;
}

.pd_t_10em {
  padding-top: 10em !important;
}

.pd_t_10rem {
  padding-top: 10rem !important;
}

.ma_r_0 {
  margin-right: 0 !important;
}

.pd_r_0 {
  padding-right: 0 !important;
}

.ma_r_5px {
  margin-right: 5px !important;
}

.pd_r_5px {
  padding-right: 5px !important;
}

.ma_r_10px {
  margin-right: 10px !important;
}

.pd_r_10px {
  padding-right: 10px !important;
}

.ma_r_15px {
  margin-right: 15px !important;
}

.pd_r_15px {
  padding-right: 15px !important;
}

.ma_r_20px {
  margin-right: 20px !important;
}

.pd_r_20px {
  padding-right: 20px !important;
}

.ma_r_25px {
  margin-right: 25px !important;
}

.pd_r_25px {
  padding-right: 25px !important;
}

.ma_r_30px {
  margin-right: 30px !important;
}

.pd_r_30px {
  padding-right: 30px !important;
}

.ma_r_45px {
  margin-right: 45px !important;
}

.pd_r_45px {
  padding-right: 45px !important;
}

.ma_r_50px {
  margin-right: 50px !important;
}

.pd_r_50px {
  padding-right: 50px !important;
}

.ma_r_1em {
  margin-right: 1em !important;
}

.ma_r_1rem {
  margin-right: 1rem !important;
}

.pd_r_1em {
  padding-right: 1em !important;
}

.pd_r_1rem {
  padding-right: 1rem !important;
}

.ma_r_2em {
  margin-right: 2em !important;
}

.ma_r_2rem {
  margin-right: 2rem !important;
}

.pd_r_2em {
  padding-right: 2em !important;
}

.pd_r_2rem {
  padding-right: 2rem !important;
}

.ma_r_3em {
  margin-right: 3em !important;
}

.ma_r_3rem {
  margin-right: 3rem !important;
}

.pd_r_3em {
  padding-right: 3em !important;
}

.pd_r_3rem {
  padding-right: 3rem !important;
}

.ma_r_4em {
  margin-right: 4em !important;
}

.ma_r_4rem {
  margin-right: 4rem !important;
}

.pd_r_4em {
  padding-right: 4em !important;
}

.pd_r_4rem {
  padding-right: 4rem !important;
}

.ma_r_5em {
  margin-right: 5em !important;
}

.ma_r_5rem {
  margin-right: 5rem !important;
}

.pd_r_5em {
  padding-right: 5em !important;
}

.pd_r_5rem {
  padding-right: 5rem !important;
}

.ma_r_6em {
  margin-right: 6em !important;
}

.ma_r_6rem {
  margin-right: 6rem !important;
}

.pd_r_6em {
  padding-right: 6em !important;
}

.pd_r_6rem {
  padding-right: 6rem !important;
}

.ma_r_7em {
  margin-right: 7em !important;
}

.ma_r_7rem {
  margin-right: 7rem !important;
}

.pd_r_7em {
  padding-right: 7em !important;
}

.pd_r_7rem {
  padding-right: 7rem !important;
}

.ma_r_8em {
  margin-right: 8em !important;
}

.ma_r_8rem {
  margin-right: 8rem !important;
}

.pd_r_8em {
  padding-right: 8em !important;
}

.pd_r_8rem {
  padding-right: 8rem !important;
}

.ma_r_9em {
  margin-right: 9em !important;
}

.ma_r_9rem {
  margin-right: 9rem !important;
}

.pd_r_9em {
  padding-right: 9em !important;
}

.pd_r_9rem {
  padding-right: 9rem !important;
}

.ma_r_10em {
  margin-right: 10em !important;
}

.ma_r_10rem {
  margin-right: 10rem !important;
}

.pd_r_10em {
  padding-right: 10em !important;
}

.pd_r_10rem {
  padding-right: 10rem !important;
}

.ma_b_0 {
  margin-bottom: 0 !important;
}

.pd_b_0 {
  padding-bottom: 0 !important;
}

.ma_b_5px {
  margin-bottom: 5px !important;
}

.pd_b_5px {
  padding-bottom: 5px !important;
}

.ma_b_10px {
  margin-bottom: 10px !important;
}

.pd_b_10px {
  padding-bottom: 10px !important;
}

.ma_b_15px {
  margin-bottom: 15px !important;
}

.pd_b_15px {
  padding-bottom: 15px !important;
}

.ma_b_20px {
  margin-bottom: 20px !important;
}

.pd_b_20px {
  padding-bottom: 20px !important;
}

.ma_b_25px {
  margin-bottom: 25px !important;
}

.pd_b_25px {
  padding-bottom: 25px !important;
}

.ma_b_30px {
  margin-bottom: 30px !important;
}

.pd_b_30px {
  padding-bottom: 30px !important;
}

.ma_b_45px {
  margin-bottom: 45px !important;
}

.pd_b_45px {
  padding-bottom: 45px !important;
}

.ma_b_50px {
  margin-bottom: 50px !important;
}

.pd_b_50px {
  padding-bottom: 50px !important;
}

.ma_b_1em {
  margin-bottom: 1em !important;
}

.ma_b_1rem {
  margin-bottom: 1rem !important;
}

.pd_b_1em {
  padding-bottom: 1em !important;
}

.pd_b_1rem {
  padding-bottom: 1rem !important;
}

.ma_b_2em {
  margin-bottom: 2em !important;
}

.ma_b_2rem {
  margin-bottom: 2rem !important;
}

.pd_b_2em {
  padding-bottom: 2em !important;
}

.pd_b_2rem {
  padding-bottom: 2rem !important;
}

.ma_b_3em {
  margin-bottom: 3em !important;
}

.ma_b_3rem {
  margin-bottom: 3rem !important;
}

.pd_b_3em {
  padding-bottom: 3em !important;
}

.pd_b_3rem {
  padding-bottom: 3rem !important;
}

.ma_b_4em {
  margin-bottom: 4em !important;
}

.ma_b_4rem {
  margin-bottom: 4rem !important;
}

.pd_b_4em {
  padding-bottom: 4em !important;
}

.pd_b_4rem {
  padding-bottom: 4rem !important;
}

.ma_b_5em {
  margin-bottom: 5em !important;
}

.ma_b_5rem {
  margin-bottom: 5rem !important;
}

.pd_b_5em {
  padding-bottom: 5em !important;
}

.pd_b_5rem {
  padding-bottom: 5rem !important;
}

.ma_b_6em {
  margin-bottom: 6em !important;
}

.ma_b_6rem {
  margin-bottom: 6rem !important;
}

.pd_b_6em {
  padding-bottom: 6em !important;
}

.pd_b_6rem {
  padding-bottom: 6rem !important;
}

.ma_b_7em {
  margin-bottom: 7em !important;
}

.ma_b_7rem {
  margin-bottom: 7rem !important;
}

.pd_b_7em {
  padding-bottom: 7em !important;
}

.pd_b_7rem {
  padding-bottom: 7rem !important;
}

.ma_b_8em {
  margin-bottom: 8em !important;
}

.ma_b_8rem {
  margin-bottom: 8rem !important;
}

.pd_b_8em {
  padding-bottom: 8em !important;
}

.pd_b_8rem {
  padding-bottom: 8rem !important;
}

.ma_b_9em {
  margin-bottom: 9em !important;
}

.ma_b_9rem {
  margin-bottom: 9rem !important;
}

.pd_b_9em {
  padding-bottom: 9em !important;
}

.pd_b_9rem {
  padding-bottom: 9rem !important;
}

.ma_b_10em {
  margin-bottom: 10em !important;
}

.ma_b_10rem {
  margin-bottom: 10rem !important;
}

.pd_b_10em {
  padding-bottom: 10em !important;
}

.pd_b_10rem {
  padding-bottom: 10rem !important;
}

.ma_l_0 {
  margin-left: 0 !important;
}

.pd_l_0 {
  padding-left: 0 !important;
}

.ma_l_5px {
  margin-left: 5px !important;
}

.pd_l_5px {
  padding-left: 5px !important;
}

.ma_l_10px {
  margin-left: 10px !important;
}

.pd_l_10px {
  padding-left: 10px !important;
}

.ma_l_15px {
  margin-left: 15px !important;
}

.pd_l_15px {
  padding-left: 15px !important;
}

.ma_l_20px {
  margin-left: 20px !important;
}

.pd_l_20px {
  padding-left: 20px !important;
}

.ma_l_25px {
  margin-left: 25px !important;
}

.pd_l_25px {
  padding-left: 25px !important;
}

.ma_l_30px {
  margin-left: 30px !important;
}

.pd_l_30px {
  padding-left: 30px !important;
}

.ma_l_45px {
  margin-left: 45px !important;
}

.pd_l_45px {
  padding-left: 45px !important;
}

.ma_l_50px {
  margin-left: 50px !important;
}

.pd_l_50px {
  padding-left: 50px !important;
}

.ma_l_1em {
  margin-left: 1em !important;
}

.ma_l_1rem {
  margin-left: 1rem !important;
}

.pd_l_1em {
  padding-left: 1em !important;
}

.pd_l_1rem {
  padding-left: 1rem !important;
}

.ma_l_2em {
  margin-left: 2em !important;
}

.ma_l_2rem {
  margin-left: 2rem !important;
}

.pd_l_2em {
  padding-left: 2em !important;
}

.pd_l_2rem {
  padding-left: 2rem !important;
}

.ma_l_3em {
  margin-left: 3em !important;
}

.ma_l_3rem {
  margin-left: 3rem !important;
}

.pd_l_3em {
  padding-left: 3em !important;
}

.pd_l_3rem {
  padding-left: 3rem !important;
}

.ma_l_4em {
  margin-left: 4em !important;
}

.ma_l_4rem {
  margin-left: 4rem !important;
}

.pd_l_4em {
  padding-left: 4em !important;
}

.pd_l_4rem {
  padding-left: 4rem !important;
}

.ma_l_5em {
  margin-left: 5em !important;
}

.ma_l_5rem {
  margin-left: 5rem !important;
}

.pd_l_5em {
  padding-left: 5em !important;
}

.pd_l_5rem {
  padding-left: 5rem !important;
}

.ma_l_6em {
  margin-left: 6em !important;
}

.ma_l_6rem {
  margin-left: 6rem !important;
}

.pd_l_6em {
  padding-left: 6em !important;
}

.pd_l_6rem {
  padding-left: 6rem !important;
}

.ma_l_7em {
  margin-left: 7em !important;
}

.ma_l_7rem {
  margin-left: 7rem !important;
}

.pd_l_7em {
  padding-left: 7em !important;
}

.pd_l_7rem {
  padding-left: 7rem !important;
}

.ma_l_8em {
  margin-left: 8em !important;
}

.ma_l_8rem {
  margin-left: 8rem !important;
}

.pd_l_8em {
  padding-left: 8em !important;
}

.pd_l_8rem {
  padding-left: 8rem !important;
}

.ma_l_9em {
  margin-left: 9em !important;
}

.ma_l_9rem {
  margin-left: 9rem !important;
}

.pd_l_9em {
  padding-left: 9em !important;
}

.pd_l_9rem {
  padding-left: 9rem !important;
}

.ma_l_10em {
  margin-left: 10em !important;
}

.ma_l_10rem {
  margin-left: 10rem !important;
}

.pd_l_10em {
  padding-left: 10em !important;
}

.pd_l_10rem {
  padding-left: 10rem !important;
}

.ma_rl_auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

/*　text-align　*/
.text_align_left {
  text-align: left !important;
}

.text_align_center {
  text-align: center !important;
}

.text_align_right {
  text-align: right !important;
}

/*　vertical-align　*/
.v_align_top {
  vertical-align: top !important;
}

.v_align_middle {
  vertical-align: middle !important;
}

.v_align_bottom {
  vertical-align: bottom !important;
}

/*　font　*/
.font_bold {
  font-weight: bold !important;
}

.font_normal {
  font-weight: normal !important;
}

.fontsize_10px {
  font-size: 10px !important;
}

.fontsize_12px {
  font-size: 12px !important;
}

.fontsize_14px {
  font-size: 14px !important;
}

.fontsize_18px {
  font-size: 18px !important;
}

.fontsize_16px {
  font-size: 16px !important;
}

.fontsize_20px {
  font-size: 20px !important;
}

.fontsize_21px {
  font-size: 21px !important;
}

.fontsize_24px {
  font-size: 24px !important;
}

.fontsize_0_75rem {
  font-size: 0.75rem !important;
}

.fontsize_1rem {
  font-size: 1rem !important;
}

.fontsize_1_25rem {
  font-size: 1.25rem !important;
}

.fontsize_1_5rem {
  font-size: 1.5rem !important;
}

.fontsize_1_75rem {
  font-size: 1.75rem !important;
}

.fontsize_2rem {
  font-size: 2rem !important;
}

/*　インデント　*/
.text_indent_0 {
  text-indent: 0 !important;
}

.text_indent_10px {
  text-indent: 10px !important;
}

.text_indent_15px {
  text-indent: 15px !important;
}

.text_indent_20px {
  text-indent: 20px !important;
}

.text_indent_-15px {
  text-indent: -15px !important;
}

.text_indent_-20px {
  text-indent: -20px !important;
}

.text_indent_-30px {
  text-indent: -30px !important;
}

/*　ラインヘイト　*/
.line_height_0 {
  line-height: 0 !important;
}

.line_height_16 {
  line-height: 16px !important;
}

.line_height_18 {
  line-height: 18px !important;
}

.line_height_24 {
  line-height: 24px !important;
}

.line_height_32 {
  line-height: 32px !important;
}

/*リスト*/
.list_style_none {
  list-style: none !important;
}

/*　横幅　*/
.width_auto {
  width: auto !important;
}

.width_10pa {
  width: 10% !important;
}

.width_1em {
  width: 1em !important;
}

.width_20pa {
  width: 20% !important;
}

.width_2em {
  width: 2em !important;
}

.width_30pa {
  width: 30% !important;
}

.width_3em {
  width: 3em !important;
}

.width_40pa {
  width: 40% !important;
}

.width_4em {
  width: 4em !important;
}

.width_50pa {
  width: 50% !important;
}

.width_5em {
  width: 5em !important;
}

.width_60pa {
  width: 60% !important;
}

.width_6em {
  width: 6em !important;
}

.width_70pa {
  width: 70% !important;
}

.width_7em {
  width: 7em !important;
}

.width_80pa {
  width: 80% !important;
}

.width_8em {
  width: 8em !important;
}

.width_90pa {
  width: 90% !important;
}

.width_9em {
  width: 9em !important;
}

.width_100pa {
  width: 100% !important;
}

.width_10em {
  width: 10em !important;
}

/* Content Space */
.content_space_padding {
  padding: 100px;
}

.content_space_padding_top {
  padding-top: 100px;
}

.content_space_padding_right {
  padding-right: 100px;
}

.content_space_padding_left {
  padding-left: 100px;
}

.content_space_padding_bottom {
  padding-bottom: 100px;
}

.content_space_margin {
  margin: 100px;
}

.content_space_margin_top {
  margin-top: 100px;
}

.content_space_margin_right {
  margin-right: 100px;
}

.content_space_margin_left {
  margin-left: 100px;
}

.content_space_margin_bottom {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .content_space_padding {
    padding: 16.1vw;
  }
  .content_space_padding_top {
    padding-top: 16.1vw;
  }
  .content_space_padding_right {
    padding-right: 16.1vw;
  }
  .content_space_padding_left {
    padding-left: 16.1vw;
  }
  .content_space_padding_bottom {
    padding-bottom: 16.1vw;
  }
  .content_space_margin {
    margin: 16.1vw;
  }
  .content_space_margin_top {
    margin-top: 16.1vw;
  }
  .content_space_margin_right {
    margin-right: 16.1vw;
  }
  .content_space_margin_left {
    margin-left: 16.1vw;
  }
  .content_space_margin_bottom {
    margin-bottom: 16.1vw;
  }
}

/* Basic Space */
.basic_space1_padding {
  padding: 100px;
}

.basic_space1_padding_top {
  padding-top: 100px;
}

.basic_space1_padding_right {
  padding-right: 100px;
}

.basic_space1_padding_left {
  padding-left: 100px;
}

.basic_space1_padding_bottom {
  padding-bottom: 100px;
}

.basic_space2_padding {
  padding: 90px;
}

.basic_space2_padding_top {
  padding-top: 90px;
}

.basic_space2_padding_right {
  padding-right: 90px;
}

.basic_space2_padding_left {
  padding-left: 90px;
}

.basic_space2_padding_bottom {
  padding-bottom: 90px;
}

.basic_space3_padding {
  padding: 60px;
}

.basic_space3_padding_top {
  padding-top: 60px;
}

.basic_space3_padding_right {
  padding-right: 60px;
}

.basic_space3_padding_left {
  padding-left: 60px;
}

.basic_space3_padding_bottom {
  padding-bottom: 60px;
}

.basic_space4_padding {
  padding: 40px;
}

.basic_space4_padding_top {
  padding-top: 40px;
}

.basic_space4_padding_right {
  padding-right: 40px;
}

.basic_space4_padding_left {
  padding-left: 40px;
}

.basic_space4_padding_bottom {
  padding-bottom: 40px;
}

.basic_space5_padding {
  padding: 25px;
}

.basic_space5_padding_top {
  padding-top: 25px;
}

.basic_space5_padding_right {
  padding-right: 25px;
}

.basic_space5_padding_left {
  padding-left: 25px;
}

.basic_space5_padding_bottom {
  padding-bottom: 25px;
}

.basic_space6_padding {
  padding: 20px;
}

.basic_space6_padding_top {
  padding-top: 20px;
}

.basic_space6_padding_right {
  padding-right: 20px;
}

.basic_space6_padding_left {
  padding-left: 20px;
}

.basic_space6_padding_bottom {
  padding-bottom: 20px;
}

.basic_space1_margin {
  margin: 100px;
}

.basic_space1_margin_top {
  margin-top: 100px;
}

.basic_space1_margin_right {
  margin-right: 100px;
}

.basic_space1_margin_left {
  margin-left: 100px;
}

.basic_space1_margin_bottom {
  margin-bottom: 100px;
}

.basic_space2_margin {
  margin: 90px;
}

.basic_space2_margin_top {
  margin-top: 90px;
}

.basic_space2_margin_right {
  margin-right: 90px;
}

.basic_space2_margin_left {
  margin-left: 90px;
}

.basic_space2_margin_bottom {
  margin-bottom: 90px;
}

.basic_space3_margin {
  margin: 60px;
}

.basic_space3_margin_top {
  margin-top: 60px;
}

.basic_space3_margin_right {
  margin-right: 60px;
}

.basic_space3_margin_left {
  margin-left: 60px;
}

.basic_space3_margin_bottom {
  margin-bottom: 60px;
}

.basic_space4_margin {
  margin: 40px;
}

.basic_space4_margin_top {
  margin-top: 40px;
}

.basic_space4_margin_right {
  margin-right: 40px;
}

.basic_space4_margin_left {
  margin-left: 40px;
}

.basic_space4_margin_bottom {
  margin-bottom: 40px;
}

.basic_space5_margin {
  margin: 25px;
}

.basic_space5_margin_top {
  margin-top: 25px;
}

.basic_space5_margin_right {
  margin-right: 25px;
}

.basic_space5_margin_left {
  margin-left: 25px;
}

.basic_space5_margin_bottom {
  margin-bottom: 25px;
}

.basic_space6_margin {
  margin: 20px;
}

.basic_space6_margin_top {
  margin-top: 20px;
}

.basic_space6_margin_right {
  margin-right: 20px;
}

.basic_space6_margin_left {
  margin-left: 20px;
}

.basic_space6_margin_bottom {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .basic_space1_padding {
    padding: 80px;
  }
  .basic_space1_padding_top {
    padding-top: 80px;
  }
  .basic_space1_padding_right {
    padding-right: 80px;
  }
  .basic_space1_padding_left {
    padding-left: 80px;
  }
  .basic_space1_padding_bottom {
    padding-bottom: 80px;
  }
  .basic_space2_padding {
    padding: 60px;
  }
  .basic_space2_padding_top {
    padding-top: 60px;
  }
  .basic_space2_padding_right {
    padding-right: 60px;
  }
  .basic_space2_padding_left {
    padding-left: 60px;
  }
  .basic_space2_padding_bottom {
    padding-bottom: 60px;
  }
  .basic_space3_padding {
    padding: 40px;
  }
  .basic_space3_padding_top {
    padding-top: 40px;
  }
  .basic_space3_padding_right {
    padding-right: 40px;
  }
  .basic_space3_padding_left {
    padding-left: 40px;
  }
  .basic_space3_padding_bottom {
    padding-bottom: 40px;
  }
  .basic_space4_padding {
    padding: 30px;
  }
  .basic_space4_padding_top {
    padding-top: 30px;
  }
  .basic_space4_padding_right {
    padding-right: 30px;
  }
  .basic_space4_padding_left {
    padding-left: 30px;
  }
  .basic_space4_padding_bottom {
    padding-bottom: 30px;
  }
  .basic_space5_padding {
    padding: 20px;
  }
  .basic_space5_padding_top {
    padding-top: 20px;
  }
  .basic_space5_padding_right {
    padding-right: 20px;
  }
  .basic_space5_padding_left {
    padding-left: 20px;
  }
  .basic_space5_padding_bottom {
    padding-bottom: 20px;
  }
  .basic_space6_padding {
    padding: 15px;
  }
  .basic_space6_padding_top {
    padding-top: 15px;
  }
  .basic_space6_padding_right {
    padding-right: 15px;
  }
  .basic_space6_padding_left {
    padding-left: 15px;
  }
  .basic_space6_padding_bottom {
    padding-bottom: 15px;
  }
  .basic_space1_margin {
    margin: 80px;
  }
  .basic_space1_margin_top {
    margin-top: 80px;
  }
  .basic_space1_margin_right {
    margin-right: 80px;
  }
  .basic_space1_margin_left {
    margin-left: 80px;
  }
  .basic_space1_margin_bottom {
    margin-bottom: 80px;
  }
  .basic_space2_margin {
    margin: 60px;
  }
  .basic_space2_margin_top {
    margin-top: 60px;
  }
  .basic_space2_margin_right {
    margin-right: 60px;
  }
  .basic_space2_margin_left {
    margin-left: 60px;
  }
  .basic_space2_margin_bottom {
    margin-bottom: 60px;
  }
  .basic_space3_margin {
    margin: 40px;
  }
  .basic_space3_margin_top {
    margin-top: 40px;
  }
  .basic_space3_margin_right {
    margin-right: 40px;
  }
  .basic_space3_margin_left {
    margin-left: 40px;
  }
  .basic_space3_margin_bottom {
    margin-bottom: 40px;
  }
  .basic_space4_margin {
    margin: 30px;
  }
  .basic_space4_margin_top {
    margin-top: 30px;
  }
  .basic_space4_margin_right {
    margin-right: 30px;
  }
  .basic_space4_margin_left {
    margin-left: 30px;
  }
  .basic_space4_margin_bottom {
    margin-bottom: 30px;
  }
  .basic_space5_margin {
    margin: 20px;
  }
  .basic_space5_margin_top {
    margin-top: 20px;
  }
  .basic_space5_margin_right {
    margin-right: 20px;
  }
  .basic_space5_margin_left {
    margin-left: 20px;
  }
  .basic_space5_margin_bottom {
    margin-bottom: 20px;
  }
  .basic_space6_margin {
    margin: 15px;
  }
  .basic_space6_margin_top {
    margin-top: 15px;
  }
  .basic_space6_margin_right {
    margin-right: 15px;
  }
  .basic_space6_margin_left {
    margin-left: 15px;
  }
  .basic_space6_margin_bottom {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .basic_space1_padding {
    padding: 16.1vw;
  }
  .basic_space1_padding_top {
    padding-top: 16.1vw;
  }
  .basic_space1_padding_right {
    padding-right: 16.1vw;
  }
  .basic_space1_padding_left {
    padding-left: 16.1vw;
  }
  .basic_space1_padding_bottom {
    padding-bottom: 16.1vw;
  }
  .basic_space2_padding {
    padding: 16vw;
  }
  .basic_space2_padding_top {
    padding-top: 16vw;
  }
  .basic_space2_padding_right {
    padding-right: 16vw;
  }
  .basic_space2_padding_left {
    padding-left: 16vw;
  }
  .basic_space2_padding_bottom {
    padding-bottom: 16vw;
  }
  .basic_space3_padding {
    padding: 8vw;
  }
  .basic_space3_padding_top {
    padding-top: 8vw;
  }
  .basic_space3_padding_right {
    padding-right: 8vw;
  }
  .basic_space3_padding_left {
    padding-left: 8vw;
  }
  .basic_space3_padding_bottom {
    padding-bottom: 8vw;
  }
  .basic_space4_padding {
    padding: 5.33vw;
  }
  .basic_space4_padding_top {
    padding-top: 5.33vw;
  }
  .basic_space4_padding_right {
    padding-right: 5.33vw;
  }
  .basic_space4_padding_left {
    padding-left: 5.33vw;
  }
  .basic_space4_padding_bottom {
    padding-bottom: 5.33vw;
  }
  .basic_space5_padding {
    padding: 4vw;
  }
  .basic_space5_padding_top {
    padding-top: 4vw;
  }
  .basic_space5_padding_right {
    padding-right: 4vw;
  }
  .basic_space5_padding_left {
    padding-left: 4vw;
  }
  .basic_space5_padding_bottom {
    padding-bottom: 4vw;
  }
  .basic_space6_padding {
    padding: 2.67vw;
  }
  .basic_space6_padding_top {
    padding-top: 2.67vw;
  }
  .basic_space6_padding_right {
    padding-right: 2.67vw;
  }
  .basic_space6_padding_left {
    padding-left: 2.67vw;
  }
  .basic_space6_padding_bottom {
    padding-bottom: 2.67vw;
  }
  .basic_space1_margin {
    margin: 16.1vw;
  }
  .basic_space1_margin_top {
    margin-top: 16.1vw;
  }
  .basic_space1_margin_right {
    margin-right: 16.1vw;
  }
  .basic_space1_margin_left {
    margin-left: 16.1vw;
  }
  .basic_space1_margin_bottom {
    margin-bottom: 16.1vw;
  }
  .basic_space2_margin {
    margin: 16vw;
  }
  .basic_space2_margin_top {
    margin-top: 16vw;
  }
  .basic_space2_margin_right {
    margin-right: 16vw;
  }
  .basic_space2_margin_left {
    margin-left: 16vw;
  }
  .basic_space2_margin_bottom {
    margin-bottom: 16vw;
  }
  .basic_space3_margin {
    margin: 8vw;
  }
  .basic_space3_margin_top {
    margin-top: 8vw;
  }
  .basic_space3_margin_right {
    margin-right: 8vw;
  }
  .basic_space3_margin_left {
    margin-left: 8vw;
  }
  .basic_space3_margin_bottom {
    margin-bottom: 8vw;
  }
  .basic_space4_margin {
    margin: 5.33vw;
  }
  .basic_space4_margin_top {
    margin-top: 5.33vw;
  }
  .basic_space4_margin_right {
    margin-right: 5.33vw;
  }
  .basic_space4_margin_left {
    margin-left: 5.33vw;
  }
  .basic_space4_margin_bottom {
    margin-bottom: 5.33vw;
  }
  .basic_space5_margin {
    margin: 4vw;
  }
  .basic_space5_margin_top {
    margin-top: 4vw;
  }
  .basic_space5_margin_right {
    margin-right: 4vw;
  }
  .basic_space5_margin_left {
    margin-left: 4vw;
  }
  .basic_space5_margin_bottom {
    margin-bottom: 4vw;
  }
  .basic_space6_margin {
    margin: 2.67vw;
  }
  .basic_space6_margin_top {
    margin-top: 2.67vw;
  }
  .basic_space6_margin_right {
    margin-right: 2.67vw;
  }
  .basic_space6_margin_left {
    margin-left: 2.67vw;
  }
  .basic_space6_margin_bottom {
    margin-bottom: 2.67vw;
  }
}

@media (max-width: 767px) {
  /* CSS Document */
  .sp_word_break_all {
    word-break: break-all !important;
  }
  .sp_float_left {
    float: left !important;
  }
  .sp_float_right {
    float: right !important;
  }
  .sp_clear_both {
    clear: both !important;
  }
  /*　ボーダー関連　*/
  .sp_border_none {
    border: none !important;
  }
  /*　display関連　*/
  .sp_display_block {
    display: block !important;
  }
  .sp_display_none {
    display: none !important;
  }
  .sp_display_inlineblock {
    display: inline-block !important;
  }
  /*flex関連　*/
  .sp_display_flex {
    display: flex !important;
  }
  .sp_justify_start {
    justify-content: flex-start !important;
  }
  .sp_justify_center {
    justify-content: center !important;
  }
  .sp_justify_end {
    justify-content: flex-end !important;
  }
  .sp_justify_around {
    justify-content: space-around !important;
  }
  .sp_justify_between {
    justify-content: space-between !important;
  }
  .sp_align_items_start {
    align-items: flex-start !important;
  }
  .sp_align_items_center {
    align-items: center !important;
  }
  .sp_align_items_end {
    align-items: flex-end !important;
  }
  .sp_flex_wrap_wrap {
    flex-wrap: wrap !important;
  }
  .sp_flex_wrap_nowrap {
    flex-wrap: nowrap !important;
  }
  /*　その他　*/
  .sp_pointer_none {
    pointer-events: none !important;
  }
  .sp_overflow-hidden {
    overflow: hidden !important;
  }
  .sp_overflow-auto {
    overflow: auto !important;
  }
  .sp_overflow-xscroll {
    overflow-x: scroll !important;
  }
  .sp_white_space_nowrap {
    white-space: nowrap !important;
  }
  .sp_white_space_normal {
    white-space: normal !important;
  }
  .sp_align_item_flex_start {
    align-items: flex-start !important;
  }
  .sp_align_item_center {
    align-items: center !important;
  }
  .sp_align_item_flex_end {
    align-items: flex-end !important;
  }
  /*　position　*/
  .sp_position_static {
    position: static !important;
  }
  .sp_position_relative {
    position: relative !important;
  }
  .sp_position_absolute {
    position: absolute !important;
  }
  .sp_position_fixed {
    position: fixed !important;
  }
  /*　margin padding　*/
  .sp_ma_0 {
    margin: 0 !important;
  }
  .sp_ma_5px {
    margin: 5px !important;
  }
  .sp_ma_10px {
    margin: 10px !important;
  }
  .sp_ma_15px {
    margin: 15px !important;
  }
  .sp_ma_20px {
    margin: 20px !important;
  }
  .sp_ma_25px {
    margin: 25px !important;
  }
  .sp_ma_30px {
    margin: 30px !important;
  }
  .sp_ma_45px {
    margin: 45px !important;
  }
  .sp_ma_50px {
    margin: 50px !important;
  }
  .sp_ma_t_0 {
    margin-top: 0 !important;
  }
  .sp_pd_t_0 {
    padding-top: 0 !important;
  }
  .sp_ma_t_5px {
    margin-top: 5px !important;
  }
  .sp_pd_t_5px {
    padding-top: 5px !important;
  }
  .sp_ma_t_10px {
    margin-top: 10px !important;
  }
  .sp_pd_t_10px {
    padding-top: 10px !important;
  }
  .sp_ma_t_15px {
    margin-top: 15px !important;
  }
  .sp_pd_t_15px {
    padding-top: 15px !important;
  }
  .sp_ma_t_20px {
    margin-top: 20px !important;
  }
  .sp_pd_t_20px {
    padding-top: 20px !important;
  }
  .sp_ma_t_25px {
    margin-top: 25px !important;
  }
  .sp_pd_t_25px {
    padding-top: 25px !important;
  }
  .sp_ma_t_30px {
    margin-top: 30px !important;
  }
  .sp_pd_t_30px {
    padding-top: 30px !important;
  }
  .sp_ma_t_45px {
    margin-top: 45px !important;
  }
  .sp_pd_t_45px {
    padding-top: 45px !important;
  }
  .sp_ma_t_50px {
    margin-top: 50px !important;
  }
  .sp_pd_t_50px {
    padding-top: 50px !important;
  }
  .sp_ma_t_1em {
    margin-top: 1em !important;
  }
  .sp_ma_t_1rem {
    margin-top: 1rem !important;
  }
  .sp_pd_t_1em {
    padding-top: 1em !important;
  }
  .sp_pd_t_1rem {
    padding-top: 1rem !important;
  }
  .sp_ma_t_2em {
    margin-top: 2em !important;
  }
  .sp_ma_t_2rem {
    margin-top: 2rem !important;
  }
  .sp_pd_t_2em {
    padding-top: 2em !important;
  }
  .sp_pd_t_2rem {
    padding-top: 2rem !important;
  }
  .sp_ma_t_3em {
    margin-top: 3em !important;
  }
  .sp_ma_t_3rem {
    margin-top: 3rem !important;
  }
  .sp_pd_t_3em {
    padding-top: 3em !important;
  }
  .sp_pd_t_3rem {
    padding-top: 3rem !important;
  }
  .sp_ma_t_4em {
    margin-top: 4em !important;
  }
  .sp_ma_t_4rem {
    margin-top: 4rem !important;
  }
  .sp_pd_t_4em {
    padding-top: 4em !important;
  }
  .sp_pd_t_4rem {
    padding-top: 4rem !important;
  }
  .sp_ma_t_5em {
    margin-top: 5em !important;
  }
  .sp_ma_t_5rem {
    margin-top: 5rem !important;
  }
  .sp_pd_t_5em {
    padding-top: 5em !important;
  }
  .sp_pd_t_5rem {
    padding-top: 5rem !important;
  }
  .sp_ma_t_6em {
    margin-top: 6em !important;
  }
  .sp_ma_t_6rem {
    margin-top: 6rem !important;
  }
  .sp_pd_t_6em {
    padding-top: 6em !important;
  }
  .sp_pd_t_6rem {
    padding-top: 6rem !important;
  }
  .sp_ma_t_7em {
    margin-top: 7em !important;
  }
  .sp_ma_t_7rem {
    margin-top: 7rem !important;
  }
  .sp_pd_t_7em {
    padding-top: 7em !important;
  }
  .sp_pd_t_7rem {
    padding-top: 7rem !important;
  }
  .sp_ma_t_8em {
    margin-top: 8em !important;
  }
  .sp_ma_t_8rem {
    margin-top: 8rem !important;
  }
  .sp_pd_t_8em {
    padding-top: 8em !important;
  }
  .sp_pd_t_8rem {
    padding-top: 8rem !important;
  }
  .sp_ma_t_9em {
    margin-top: 9em !important;
  }
  .sp_ma_t_9rem {
    margin-top: 9rem !important;
  }
  .sp_pd_t_9em {
    padding-top: 9em !important;
  }
  .sp_pd_t_9rem {
    padding-top: 9rem !important;
  }
  .sp_ma_t_10em {
    margin-top: 10em !important;
  }
  .sp_ma_t_10rem {
    margin-top: 10rem !important;
  }
  .sp_pd_t_10em {
    padding-top: 10em !important;
  }
  .sp_pd_t_10rem {
    padding-top: 10rem !important;
  }
  .sp_ma_r_0 {
    margin-right: 0 !important;
  }
  .sp_pd_r_0 {
    padding-right: 0 !important;
  }
  .sp_ma_r_5px {
    margin-right: 5px !important;
  }
  .sp_pd_r_5px {
    padding-right: 5px !important;
  }
  .sp_ma_r_10px {
    margin-right: 10px !important;
  }
  .sp_pd_r_10px {
    padding-right: 10px !important;
  }
  .sp_ma_r_15px {
    margin-right: 15px !important;
  }
  .sp_pd_r_15px {
    padding-right: 15px !important;
  }
  .sp_ma_r_20px {
    margin-right: 20px !important;
  }
  .sp_pd_r_20px {
    padding-right: 20px !important;
  }
  .sp_ma_r_25px {
    margin-right: 25px !important;
  }
  .sp_pd_r_25px {
    padding-right: 25px !important;
  }
  .sp_ma_r_30px {
    margin-right: 30px !important;
  }
  .sp_pd_r_30px {
    padding-right: 30px !important;
  }
  .sp_ma_r_45px {
    margin-right: 45px !important;
  }
  .sp_pd_r_45px {
    padding-right: 45px !important;
  }
  .sp_ma_r_50px {
    margin-right: 50px !important;
  }
  .sp_pd_r_50px {
    padding-right: 50px !important;
  }
  .sp_ma_r_1em {
    margin-right: 1em !important;
  }
  .sp_ma_r_1rem {
    margin-right: 1rem !important;
  }
  .sp_pd_r_1em {
    padding-right: 1em !important;
  }
  .sp_pd_r_1rem {
    padding-right: 1rem !important;
  }
  .sp_ma_r_2em {
    margin-right: 2em !important;
  }
  .sp_ma_r_2rem {
    margin-right: 2rem !important;
  }
  .sp_pd_r_2em {
    padding-right: 2em !important;
  }
  .sp_pd_r_2rem {
    padding-right: 2rem !important;
  }
  .sp_ma_r_3em {
    margin-right: 3em !important;
  }
  .sp_ma_r_3rem {
    margin-right: 3rem !important;
  }
  .sp_pd_r_3em {
    padding-right: 3em !important;
  }
  .sp_pd_r_3rem {
    padding-right: 3rem !important;
  }
  .sp_ma_r_4em {
    margin-right: 4em !important;
  }
  .sp_ma_r_4rem {
    margin-right: 4rem !important;
  }
  .sp_pd_r_4em {
    padding-right: 4em !important;
  }
  .sp_pd_r_4rem {
    padding-right: 4rem !important;
  }
  .sp_ma_r_5em {
    margin-right: 5em !important;
  }
  .sp_ma_r_5rem {
    margin-right: 5rem !important;
  }
  .sp_pd_r_5em {
    padding-right: 5em !important;
  }
  .sp_pd_r_5rem {
    padding-right: 5rem !important;
  }
  .sp_ma_r_6em {
    margin-right: 6em !important;
  }
  .sp_ma_r_6rem {
    margin-right: 6rem !important;
  }
  .sp_pd_r_6em {
    padding-right: 6em !important;
  }
  .sp_pd_r_6rem {
    padding-right: 6rem !important;
  }
  .sp_ma_r_7em {
    margin-right: 7em !important;
  }
  .sp_ma_r_7rem {
    margin-right: 7rem !important;
  }
  .sp_pd_r_7em {
    padding-right: 7em !important;
  }
  .sp_pd_r_7rem {
    padding-right: 7rem !important;
  }
  .sp_ma_r_8em {
    margin-right: 8em !important;
  }
  .sp_ma_r_8rem {
    margin-right: 8rem !important;
  }
  .sp_pd_r_8em {
    padding-right: 8em !important;
  }
  .sp_pd_r_8rem {
    padding-right: 8rem !important;
  }
  .sp_ma_r_9em {
    margin-right: 9em !important;
  }
  .sp_ma_r_9rem {
    margin-right: 9rem !important;
  }
  .sp_pd_r_9em {
    padding-right: 9em !important;
  }
  .sp_pd_r_9rem {
    padding-right: 9rem !important;
  }
  .sp_ma_r_10em {
    margin-right: 10em !important;
  }
  .sp_ma_r_10rem {
    margin-right: 10rem !important;
  }
  .sp_pd_r_10em {
    padding-right: 10em !important;
  }
  .sp_pd_r_10rem {
    padding-right: 10rem !important;
  }
  .sp_ma_b_0 {
    margin-bottom: 0 !important;
  }
  .sp_pd_b_0 {
    padding-bottom: 0 !important;
  }
  .sp_ma_b_5px {
    margin-bottom: 5px !important;
  }
  .sp_pd_b_5px {
    padding-bottom: 5px !important;
  }
  .sp_ma_b_10px {
    margin-bottom: 10px !important;
  }
  .sp_pd_b_10px {
    padding-bottom: 10px !important;
  }
  .sp_ma_b_15px {
    margin-bottom: 15px !important;
  }
  .sp_pd_b_15px {
    padding-bottom: 15px !important;
  }
  .sp_ma_b_20px {
    margin-bottom: 20px !important;
  }
  .sp_pd_b_20px {
    padding-bottom: 20px !important;
  }
  .sp_ma_b_25px {
    margin-bottom: 25px !important;
  }
  .sp_pd_b_25px {
    padding-bottom: 25px !important;
  }
  .sp_ma_b_30px {
    margin-bottom: 30px !important;
  }
  .sp_pd_b_30px {
    padding-bottom: 30px !important;
  }
  .sp_ma_b_45px {
    margin-bottom: 45px !important;
  }
  .sp_pd_b_45px {
    padding-bottom: 45px !important;
  }
  .sp_ma_b_50px {
    margin-bottom: 50px !important;
  }
  .sp_pd_b_50px {
    padding-bottom: 50px !important;
  }
  .sp_ma_b_1em {
    margin-bottom: 1em !important;
  }
  .sp_ma_b_1rem {
    margin-bottom: 1rem !important;
  }
  .sp_pd_b_1em {
    padding-bottom: 1em !important;
  }
  .sp_pd_b_1rem {
    padding-bottom: 1rem !important;
  }
  .sp_ma_b_2em {
    margin-bottom: 2em !important;
  }
  .sp_ma_b_2rem {
    margin-bottom: 2rem !important;
  }
  .sp_pd_b_2em {
    padding-bottom: 2em !important;
  }
  .sp_pd_b_2rem {
    padding-bottom: 2rem !important;
  }
  .sp_ma_b_3em {
    margin-bottom: 3em !important;
  }
  .sp_ma_b_3rem {
    margin-bottom: 3rem !important;
  }
  .sp_pd_b_3em {
    padding-bottom: 3em !important;
  }
  .sp_pd_b_3rem {
    padding-bottom: 3rem !important;
  }
  .sp_ma_b_4em {
    margin-bottom: 4em !important;
  }
  .sp_ma_b_4rem {
    margin-bottom: 4rem !important;
  }
  .sp_pd_b_4em {
    padding-bottom: 4em !important;
  }
  .sp_pd_b_4rem {
    padding-bottom: 4rem !important;
  }
  .sp_ma_b_5em {
    margin-bottom: 5em !important;
  }
  .sp_ma_b_5rem {
    margin-bottom: 5rem !important;
  }
  .sp_pd_b_5em {
    padding-bottom: 5em !important;
  }
  .sp_pd_b_5rem {
    padding-bottom: 5rem !important;
  }
  .sp_ma_b_6em {
    margin-bottom: 6em !important;
  }
  .sp_ma_b_6rem {
    margin-bottom: 6rem !important;
  }
  .sp_pd_b_6em {
    padding-bottom: 6em !important;
  }
  .sp_pd_b_6rem {
    padding-bottom: 6rem !important;
  }
  .sp_ma_b_7em {
    margin-bottom: 7em !important;
  }
  .sp_ma_b_7rem {
    margin-bottom: 7rem !important;
  }
  .sp_pd_b_7em {
    padding-bottom: 7em !important;
  }
  .sp_pd_b_7rem {
    padding-bottom: 7rem !important;
  }
  .sp_ma_b_8em {
    margin-bottom: 8em !important;
  }
  .sp_ma_b_8rem {
    margin-bottom: 8rem !important;
  }
  .sp_pd_b_8em {
    padding-bottom: 8em !important;
  }
  .sp_pd_b_8rem {
    padding-bottom: 8rem !important;
  }
  .sp_ma_b_9em {
    margin-bottom: 9em !important;
  }
  .sp_ma_b_9rem {
    margin-bottom: 9rem !important;
  }
  .sp_pd_b_9em {
    padding-bottom: 9em !important;
  }
  .sp_pd_b_9rem {
    padding-bottom: 9rem !important;
  }
  .sp_ma_b_10em {
    margin-bottom: 10em !important;
  }
  .sp_ma_b_10rem {
    margin-bottom: 10rem !important;
  }
  .sp_pd_b_10em {
    padding-bottom: 10em !important;
  }
  .sp_pd_b_10rem {
    padding-bottom: 10rem !important;
  }
  .sp_ma_l_0 {
    margin-left: 0 !important;
  }
  .sp_pd_l_0 {
    padding-left: 0 !important;
  }
  .sp_ma_l_5px {
    margin-left: 5px !important;
  }
  .sp_pd_l_5px {
    padding-left: 5px !important;
  }
  .sp_ma_l_10px {
    margin-left: 10px !important;
  }
  .sp_pd_l_10px {
    padding-left: 10px !important;
  }
  .sp_ma_l_15px {
    margin-left: 15px !important;
  }
  .sp_pd_l_15px {
    padding-left: 15px !important;
  }
  .sp_ma_l_20px {
    margin-left: 20px !important;
  }
  .sp_pd_l_20px {
    padding-left: 20px !important;
  }
  .sp_ma_l_25px {
    margin-left: 25px !important;
  }
  .sp_pd_l_25px {
    padding-left: 25px !important;
  }
  .sp_ma_l_30px {
    margin-left: 30px !important;
  }
  .sp_pd_l_30px {
    padding-left: 30px !important;
  }
  .sp_ma_l_45px {
    margin-left: 45px !important;
  }
  .sp_pd_l_45px {
    padding-left: 45px !important;
  }
  .sp_ma_l_50px {
    margin-left: 50px !important;
  }
  .sp_pd_l_50px {
    padding-left: 50px !important;
  }
  .sp_ma_l_1em {
    margin-left: 1em !important;
  }
  .sp_ma_l_1rem {
    margin-left: 1rem !important;
  }
  .sp_pd_l_1em {
    padding-left: 1em !important;
  }
  .sp_pd_l_1rem {
    padding-left: 1rem !important;
  }
  .sp_ma_l_2em {
    margin-left: 2em !important;
  }
  .sp_ma_l_2rem {
    margin-left: 2rem !important;
  }
  .sp_pd_l_2em {
    padding-left: 2em !important;
  }
  .sp_pd_l_2rem {
    padding-left: 2rem !important;
  }
  .sp_ma_l_3em {
    margin-left: 3em !important;
  }
  .sp_ma_l_3rem {
    margin-left: 3rem !important;
  }
  .sp_pd_l_3em {
    padding-left: 3em !important;
  }
  .sp_pd_l_3rem {
    padding-left: 3rem !important;
  }
  .sp_ma_l_4em {
    margin-left: 4em !important;
  }
  .sp_ma_l_4rem {
    margin-left: 4rem !important;
  }
  .sp_pd_l_4em {
    padding-left: 4em !important;
  }
  .sp_pd_l_4rem {
    padding-left: 4rem !important;
  }
  .sp_ma_l_5em {
    margin-left: 5em !important;
  }
  .sp_ma_l_5rem {
    margin-left: 5rem !important;
  }
  .sp_pd_l_5em {
    padding-left: 5em !important;
  }
  .sp_pd_l_5rem {
    padding-left: 5rem !important;
  }
  .sp_ma_l_6em {
    margin-left: 6em !important;
  }
  .sp_ma_l_6rem {
    margin-left: 6rem !important;
  }
  .sp_pd_l_6em {
    padding-left: 6em !important;
  }
  .sp_pd_l_6rem {
    padding-left: 6rem !important;
  }
  .sp_ma_l_7em {
    margin-left: 7em !important;
  }
  .sp_ma_l_7rem {
    margin-left: 7rem !important;
  }
  .sp_pd_l_7em {
    padding-left: 7em !important;
  }
  .sp_pd_l_7rem {
    padding-left: 7rem !important;
  }
  .sp_ma_l_8em {
    margin-left: 8em !important;
  }
  .sp_ma_l_8rem {
    margin-left: 8rem !important;
  }
  .sp_pd_l_8em {
    padding-left: 8em !important;
  }
  .sp_pd_l_8rem {
    padding-left: 8rem !important;
  }
  .sp_ma_l_9em {
    margin-left: 9em !important;
  }
  .sp_ma_l_9rem {
    margin-left: 9rem !important;
  }
  .sp_pd_l_9em {
    padding-left: 9em !important;
  }
  .sp_pd_l_9rem {
    padding-left: 9rem !important;
  }
  .sp_ma_l_10em {
    margin-left: 10em !important;
  }
  .sp_ma_l_10rem {
    margin-left: 10rem !important;
  }
  .sp_pd_l_10em {
    padding-left: 10em !important;
  }
  .sp_pd_l_10rem {
    padding-left: 10rem !important;
  }
  .sp_ma_rl_auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  /*　text-align　*/
  .sp_text_align_left {
    text-align: left !important;
  }
  .sp_text_align_center {
    text-align: center !important;
  }
  .sp_text_align_right {
    text-align: right !important;
  }
  /*　vertical-align　*/
  .sp_v_align_top {
    vertical-align: top !important;
  }
  .sp_v_align_middle {
    vertical-align: middle !important;
  }
  .sp_v_align_bottom {
    vertical-align: bottom !important;
  }
  /*　フォントウェイト　*/
  .sp_font_bold {
    font-weight: bold !important;
  }
  .sp_font_normal {
    font-weight: normal !important;
  }
  /*　フォントサイズ　*/
  .sp_fontsize_10px {
    font-size: 10px !important;
  }
  .sp_fontsize_12px {
    font-size: 12px !important;
  }
  .sp_fontsize_14px {
    font-size: 14px !important;
  }
  .sp_fontsize_18px {
    font-size: 18px !important;
  }
  .sp_fontsize_16px {
    font-size: 16px !important;
  }
  .sp_fontsize_20px {
    font-size: 20px !important;
  }
  .sp_fontsize_21px {
    font-size: 21px !important;
  }
  .sp_fontsize_24px {
    font-size: 24px !important;
  }
  .sp_fontsize_0_75rem {
    font-size: 0.75rem !important;
  }
  .sp_fontsize_1rem {
    font-size: 1rem !important;
  }
  .sp_fontsize_1_25rem {
    font-size: 1.25rem !important;
  }
  .sp_fontsize_1_5rem {
    font-size: 1.5rem !important;
  }
  .sp_fontsize_1_75rem {
    font-size: 1.75rem !important;
  }
  .sp_fontsize_2rem {
    font-size: 2rem !important;
  }
  /*　インデント　*/
  .sp_text_indent_10px {
    text-indent: 10px !important;
  }
  .sp_text_indent_15px {
    text-indent: 15px !important;
  }
  .sp_text_indent_20px {
    text-indent: 20px !important;
  }
  .sp_text_indent_-15px {
    text-indent: -15px !important;
  }
  .sp_text_indent_-20px {
    text-indent: -20px !important;
  }
  .sp_text_indent_-30px {
    text-indent: -30px !important;
  }
  /*　ラインヘイト　*/
  .sp_line_height_0 {
    line-height: 0 !important;
  }
  .sp_line_height_16 {
    line-height: 16px !important;
  }
  .sp_line_height_18 {
    line-height: 18px !important;
  }
  .sp_line_height_24 {
    line-height: 24px !important;
  }
  .sp_line_height_32 {
    line-height: 32px !important;
  }
  /*リスト*/
  .sp_list_style_none {
    list-style: none !important;
  }
  /*　横幅　*/
  .sp_width_auto {
    width: auto !important;
  }
  .sp_width_10pa {
    width: 10% !important;
  }
  .sp_width_1em {
    width: 1em !important;
  }
  .sp_width_20pa {
    width: 20% !important;
  }
  .sp_width_2em {
    width: 2em !important;
  }
  .sp_width_30pa {
    width: 30% !important;
  }
  .sp_width_3em {
    width: 3em !important;
  }
  .sp_width_40pa {
    width: 40% !important;
  }
  .sp_width_4em {
    width: 4em !important;
  }
  .sp_width_50pa {
    width: 50% !important;
  }
  .sp_width_5em {
    width: 5em !important;
  }
  .sp_width_60pa {
    width: 60% !important;
  }
  .sp_width_6em {
    width: 6em !important;
  }
  .sp_width_70pa {
    width: 70% !important;
  }
  .sp_width_7em {
    width: 7em !important;
  }
  .sp_width_80pa {
    width: 80% !important;
  }
  .sp_width_8em {
    width: 8em !important;
  }
  .sp_width_90pa {
    width: 90% !important;
  }
  .sp_width_9em {
    width: 9em !important;
  }
  .sp_width_100pa {
    width: 100% !important;
  }
  .sp_width_10em {
    width: 10em !important;
  }
}

@media (min-width: 768px) {
  /* CSS Document */
  .pc_word_break_all {
    word-break: break-all !important;
  }
  .pc_float_left {
    float: left !important;
  }
  .pc_float_right {
    float: right !important;
  }
  .pc_clear_both {
    clear: both !important;
  }
  /*　ボーダー関連　*/
  .pc_border_none {
    border: none !important;
  }
  /*　display関連　*/
  .pc_display_block {
    display: block !important;
  }
  .pc_display_none {
    display: none !important;
  }
  .pc_display_inlineblock {
    display: inline-block !important;
  }
  /*flex関連　*/
  .pc_display_flex {
    display: flex !important;
  }
  .pc_justify_start {
    justify-content: flex-start !important;
  }
  .pc_justify_center {
    justify-content: center !important;
  }
  .pc_justify_end {
    justify-content: flex-end !important;
  }
  .pc_justify_around {
    justify-content: space-around !important;
  }
  .pc_justify_between {
    justify-content: space-between !important;
  }
  .pc_align_items_start {
    align-items: flex-start !important;
  }
  .pc_align_items_center {
    align-items: center !important;
  }
  .pc_align_items_end {
    align-items: flex-end !important;
  }
  .pc_flex_wrap_wrap {
    flex-wrap: wrap !important;
  }
  .pc_flex_wrap_nowrap {
    flex-wrap: nowrap !important;
  }
  /*　その他　*/
  .pc_pointer_none {
    pointer-events: none !important;
  }
  .pc_overflow-hidden {
    overflow: hidden !important;
  }
  .pc_overflow-auto {
    overflow: auto !important;
  }
  .pc_overflow-xscroll {
    overflow-x: scroll !important;
  }
  .pc_white_space_nowrap {
    white-space: nowrap !important;
  }
  .pc_white_space_normal {
    white-space: normal !important;
  }
  .pc_align_item_flex_start {
    align-items: flex-start !important;
  }
  .pc_align_item_center {
    align-items: center !important;
  }
  .pc_align_item_flex_end {
    align-items: flex-end !important;
  }
  /*　position　*/
  .pc_position_static {
    position: static !important;
  }
  .pc_position_relative {
    position: relative !important;
  }
  .pc_position_absolute {
    position: absolute !important;
  }
  .pc_position_fixed {
    position: fixed !important;
  }
  /*　margin padding　*/
  .pc_ma_0 {
    margin: 0 !important;
  }
  .pc_ma_5px {
    margin: 5px !important;
  }
  .pc_ma_10px {
    margin: 10px !important;
  }
  .pc_ma_15px {
    margin: 15px !important;
  }
  .pc_ma_20px {
    margin: 20px !important;
  }
  .pc_ma_25px {
    margin: 25px !important;
  }
  .pc_ma_30px {
    margin: 30px !important;
  }
  .pc_ma_45px {
    margin: 45px !important;
  }
  .pc_ma_50px {
    margin: 50px !important;
  }
  .pc_ma_t_0 {
    margin-top: 0 !important;
  }
  .pc_pd_t_0 {
    padding-top: 0 !important;
  }
  .pc_ma_t_5px {
    margin-top: 5px !important;
  }
  .pc_pd_t_5px {
    padding-top: 5px !important;
  }
  .pc_ma_t_10px {
    margin-top: 10px !important;
  }
  .pc_pd_t_10px {
    padding-top: 10px !important;
  }
  .pc_ma_t_15px {
    margin-top: 15px !important;
  }
  .pc_pd_t_15px {
    padding-top: 15px !important;
  }
  .pc_ma_t_20px {
    margin-top: 20px !important;
  }
  .pc_pd_t_20px {
    padding-top: 20px !important;
  }
  .pc_ma_t_25px {
    margin-top: 25px !important;
  }
  .pc_pd_t_25px {
    padding-top: 25px !important;
  }
  .pc_ma_t_30px {
    margin-top: 30px !important;
  }
  .pc_pd_t_30px {
    padding-top: 30px !important;
  }
  .pc_ma_t_45px {
    margin-top: 45px !important;
  }
  .pc_pd_t_45px {
    padding-top: 45px !important;
  }
  .pc_ma_t_50px {
    margin-top: 50px !important;
  }
  .pc_pd_t_50px {
    padding-top: 50px !important;
  }
  .pc_ma_t_1em {
    margin-top: 1em !important;
  }
  .pc_ma_t_1rem {
    margin-top: 1rem !important;
  }
  .pc_pd_t_1em {
    padding-top: 1em !important;
  }
  .pc_pd_t_1rem {
    padding-top: 1rem !important;
  }
  .pc_ma_t_2em {
    margin-top: 2em !important;
  }
  .pc_ma_t_2rem {
    margin-top: 2rem !important;
  }
  .pc_pd_t_2em {
    padding-top: 2em !important;
  }
  .pc_pd_t_2rem {
    padding-top: 2rem !important;
  }
  .pc_ma_t_3em {
    margin-top: 3em !important;
  }
  .pc_ma_t_3rem {
    margin-top: 3rem !important;
  }
  .pc_pd_t_3em {
    padding-top: 3em !important;
  }
  .pc_pd_t_3rem {
    padding-top: 3rem !important;
  }
  .pc_ma_t_4em {
    margin-top: 4em !important;
  }
  .pc_ma_t_4rem {
    margin-top: 4rem !important;
  }
  .pc_pd_t_4em {
    padding-top: 4em !important;
  }
  .pc_pd_t_4rem {
    padding-top: 4rem !important;
  }
  .pc_ma_t_5em {
    margin-top: 5em !important;
  }
  .pc_ma_t_5rem {
    margin-top: 5rem !important;
  }
  .pc_pd_t_5em {
    padding-top: 5em !important;
  }
  .pc_pd_t_5rem {
    padding-top: 5rem !important;
  }
  .pc_ma_t_6em {
    margin-top: 6em !important;
  }
  .pc_ma_t_6rem {
    margin-top: 6rem !important;
  }
  .pc_pd_t_6em {
    padding-top: 6em !important;
  }
  .pc_pd_t_6rem {
    padding-top: 6rem !important;
  }
  .pc_ma_t_7em {
    margin-top: 7em !important;
  }
  .pc_ma_t_7rem {
    margin-top: 7rem !important;
  }
  .pc_pd_t_7em {
    padding-top: 7em !important;
  }
  .pc_pd_t_7rem {
    padding-top: 7rem !important;
  }
  .pc_ma_t_8em {
    margin-top: 8em !important;
  }
  .pc_ma_t_8rem {
    margin-top: 8rem !important;
  }
  .pc_pd_t_8em {
    padding-top: 8em !important;
  }
  .pc_pd_t_8rem {
    padding-top: 8rem !important;
  }
  .pc_ma_t_9em {
    margin-top: 9em !important;
  }
  .pc_ma_t_9rem {
    margin-top: 9rem !important;
  }
  .pc_pd_t_9em {
    padding-top: 9em !important;
  }
  .pc_pd_t_9rem {
    padding-top: 9rem !important;
  }
  .pc_ma_t_10em {
    margin-top: 10em !important;
  }
  .pc_ma_t_10rem {
    margin-top: 10rem !important;
  }
  .pc_pd_t_10em {
    padding-top: 10em !important;
  }
  .pc_pd_t_10rem {
    padding-top: 10rem !important;
  }
  .pc_ma_r_0 {
    margin-right: 0 !important;
  }
  .pc_pd_r_0 {
    padding-right: 0 !important;
  }
  .pc_ma_r_5px {
    margin-right: 5px !important;
  }
  .pc_pd_r_5px {
    padding-right: 5px !important;
  }
  .pc_ma_r_10px {
    margin-right: 10px !important;
  }
  .pc_pd_r_10px {
    padding-right: 10px !important;
  }
  .pc_ma_r_15px {
    margin-right: 15px !important;
  }
  .pc_pd_r_15px {
    padding-right: 15px !important;
  }
  .pc_ma_r_20px {
    margin-right: 20px !important;
  }
  .pc_pd_r_20px {
    padding-right: 20px !important;
  }
  .pc_ma_r_25px {
    margin-right: 25px !important;
  }
  .pc_pd_r_25px {
    padding-right: 25px !important;
  }
  .pc_ma_r_30px {
    margin-right: 30px !important;
  }
  .pc_pd_r_30px {
    padding-right: 30px !important;
  }
  .pc_ma_r_45px {
    margin-right: 45px !important;
  }
  .pc_pd_r_45px {
    padding-right: 45px !important;
  }
  .pc_ma_r_50px {
    margin-right: 50px !important;
  }
  .pc_pd_r_50px {
    padding-right: 50px !important;
  }
  .pc_ma_r_1em {
    margin-right: 1em !important;
  }
  .pc_ma_r_1rem {
    margin-right: 1rem !important;
  }
  .pc_pd_r_1em {
    padding-right: 1em !important;
  }
  .pc_pd_r_1rem {
    padding-right: 1rem !important;
  }
  .pc_ma_r_2em {
    margin-right: 2em !important;
  }
  .pc_ma_r_2rem {
    margin-right: 2rem !important;
  }
  .pc_pd_r_2em {
    padding-right: 2em !important;
  }
  .pc_pd_r_2rem {
    padding-right: 2rem !important;
  }
  .pc_ma_r_3em {
    margin-right: 3em !important;
  }
  .pc_ma_r_3rem {
    margin-right: 3rem !important;
  }
  .pc_pd_r_3em {
    padding-right: 3em !important;
  }
  .pc_pd_r_3rem {
    padding-right: 3rem !important;
  }
  .pc_ma_r_4em {
    margin-right: 4em !important;
  }
  .pc_ma_r_4rem {
    margin-right: 4rem !important;
  }
  .pc_pd_r_4em {
    padding-right: 4em !important;
  }
  .pc_pd_r_4rem {
    padding-right: 4rem !important;
  }
  .pc_ma_r_5em {
    margin-right: 5em !important;
  }
  .pc_ma_r_5rem {
    margin-right: 5rem !important;
  }
  .pc_pd_r_5em {
    padding-right: 5em !important;
  }
  .pc_pd_r_5rem {
    padding-right: 5rem !important;
  }
  .pc_ma_r_6em {
    margin-right: 6em !important;
  }
  .pc_ma_r_6rem {
    margin-right: 6rem !important;
  }
  .pc_pd_r_6em {
    padding-right: 6em !important;
  }
  .pc_pd_r_6rem {
    padding-right: 6rem !important;
  }
  .pc_ma_r_7em {
    margin-right: 7em !important;
  }
  .pc_ma_r_7rem {
    margin-right: 7rem !important;
  }
  .pc_pd_r_7em {
    padding-right: 7em !important;
  }
  .pc_pd_r_7rem {
    padding-right: 7rem !important;
  }
  .pc_ma_r_8em {
    margin-right: 8em !important;
  }
  .pc_ma_r_8rem {
    margin-right: 8rem !important;
  }
  .pc_pd_r_8em {
    padding-right: 8em !important;
  }
  .pc_pd_r_8rem {
    padding-right: 8rem !important;
  }
  .pc_ma_r_9em {
    margin-right: 9em !important;
  }
  .pc_ma_r_9rem {
    margin-right: 9rem !important;
  }
  .pc_pd_r_9em {
    padding-right: 9em !important;
  }
  .pc_pd_r_9rem {
    padding-right: 9rem !important;
  }
  .pc_ma_r_10em {
    margin-right: 10em !important;
  }
  .pc_ma_r_10rem {
    margin-right: 10rem !important;
  }
  .pc_pd_r_10em {
    padding-right: 10em !important;
  }
  .pc_pd_r_10rem {
    padding-right: 10rem !important;
  }
  .pc_ma_b_0 {
    margin-bottom: 0 !important;
  }
  .pc_pd_b_0 {
    padding-bottom: 0 !important;
  }
  .pc_ma_b_5px {
    margin-bottom: 5px !important;
  }
  .pc_pd_b_5px {
    padding-bottom: 5px !important;
  }
  .pc_ma_b_10px {
    margin-bottom: 10px !important;
  }
  .pc_pd_b_10px {
    padding-bottom: 10px !important;
  }
  .pc_ma_b_15px {
    margin-bottom: 15px !important;
  }
  .pc_pd_b_15px {
    padding-bottom: 15px !important;
  }
  .pc_ma_b_20px {
    margin-bottom: 20px !important;
  }
  .pc_pd_b_20px {
    padding-bottom: 20px !important;
  }
  .pc_ma_b_25px {
    margin-bottom: 25px !important;
  }
  .pc_pd_b_25px {
    padding-bottom: 25px !important;
  }
  .pc_ma_b_30px {
    margin-bottom: 30px !important;
  }
  .pc_pd_b_30px {
    padding-bottom: 30px !important;
  }
  .pc_ma_b_45px {
    margin-bottom: 45px !important;
  }
  .pc_pd_b_45px {
    padding-bottom: 45px !important;
  }
  .pc_ma_b_50px {
    margin-bottom: 50px !important;
  }
  .pc_pd_b_50px {
    padding-bottom: 50px !important;
  }
  .pc_ma_b_1em {
    margin-bottom: 1em !important;
  }
  .pc_ma_b_1rem {
    margin-bottom: 1rem !important;
  }
  .pc_pd_b_1em {
    padding-bottom: 1em !important;
  }
  .pc_pd_b_1rem {
    padding-bottom: 1rem !important;
  }
  .pc_ma_b_2em {
    margin-bottom: 2em !important;
  }
  .pc_ma_b_2rem {
    margin-bottom: 2rem !important;
  }
  .pc_pd_b_2em {
    padding-bottom: 2em !important;
  }
  .pc_pd_b_2rem {
    padding-bottom: 2rem !important;
  }
  .pc_ma_b_3em {
    margin-bottom: 3em !important;
  }
  .pc_ma_b_3rem {
    margin-bottom: 3rem !important;
  }
  .pc_pd_b_3em {
    padding-bottom: 3em !important;
  }
  .pc_pd_b_3rem {
    padding-bottom: 3rem !important;
  }
  .pc_ma_b_4em {
    margin-bottom: 4em !important;
  }
  .pc_ma_b_4rem {
    margin-bottom: 4rem !important;
  }
  .pc_pd_b_4em {
    padding-bottom: 4em !important;
  }
  .pc_pd_b_4rem {
    padding-bottom: 4rem !important;
  }
  .pc_ma_b_5em {
    margin-bottom: 5em !important;
  }
  .pc_ma_b_5rem {
    margin-bottom: 5rem !important;
  }
  .pc_pd_b_5em {
    padding-bottom: 5em !important;
  }
  .pc_pd_b_5rem {
    padding-bottom: 5rem !important;
  }
  .pc_ma_b_6em {
    margin-bottom: 6em !important;
  }
  .pc_ma_b_6rem {
    margin-bottom: 6rem !important;
  }
  .pc_pd_b_6em {
    padding-bottom: 6em !important;
  }
  .pc_pd_b_6rem {
    padding-bottom: 6rem !important;
  }
  .pc_ma_b_7em {
    margin-bottom: 7em !important;
  }
  .pc_ma_b_7rem {
    margin-bottom: 7rem !important;
  }
  .pc_pd_b_7em {
    padding-bottom: 7em !important;
  }
  .pc_pd_b_7rem {
    padding-bottom: 7rem !important;
  }
  .pc_ma_b_8em {
    margin-bottom: 8em !important;
  }
  .pc_ma_b_8rem {
    margin-bottom: 8rem !important;
  }
  .pc_pd_b_8em {
    padding-bottom: 8em !important;
  }
  .pc_pd_b_8rem {
    padding-bottom: 8rem !important;
  }
  .pc_ma_b_9em {
    margin-bottom: 9em !important;
  }
  .pc_ma_b_9rem {
    margin-bottom: 9rem !important;
  }
  .pc_pd_b_9em {
    padding-bottom: 9em !important;
  }
  .pc_pd_b_9rem {
    padding-bottom: 9rem !important;
  }
  .pc_ma_b_10em {
    margin-bottom: 10em !important;
  }
  .pc_ma_b_10rem {
    margin-bottom: 10rem !important;
  }
  .pc_pd_b_10em {
    padding-bottom: 10em !important;
  }
  .pc_pd_b_10rem {
    padding-bottom: 10rem !important;
  }
  .pc_ma_l_0 {
    margin-left: 0 !important;
  }
  .pc_pd_l_0 {
    padding-left: 0 !important;
  }
  .pc_ma_l_5px {
    margin-left: 5px !important;
  }
  .pc_pd_l_5px {
    padding-left: 5px !important;
  }
  .pc_ma_l_10px {
    margin-left: 10px !important;
  }
  .pc_pd_l_10px {
    padding-left: 10px !important;
  }
  .pc_ma_l_15px {
    margin-left: 15px !important;
  }
  .pc_pd_l_15px {
    padding-left: 15px !important;
  }
  .pc_ma_l_20px {
    margin-left: 20px !important;
  }
  .pc_pd_l_20px {
    padding-left: 20px !important;
  }
  .pc_ma_l_25px {
    margin-left: 25px !important;
  }
  .pc_pd_l_25px {
    padding-left: 25px !important;
  }
  .pc_ma_l_30px {
    margin-left: 30px !important;
  }
  .pc_pd_l_30px {
    padding-left: 30px !important;
  }
  .pc_ma_l_45px {
    margin-left: 45px !important;
  }
  .pc_pd_l_45px {
    padding-left: 45px !important;
  }
  .pc_ma_l_50px {
    margin-left: 50px !important;
  }
  .pc_pd_l_50px {
    padding-left: 50px !important;
  }
  .pc_ma_l_1em {
    margin-left: 1em !important;
  }
  .pc_ma_l_1rem {
    margin-left: 1rem !important;
  }
  .pc_pd_l_1em {
    padding-left: 1em !important;
  }
  .pc_pd_l_1rem {
    padding-left: 1rem !important;
  }
  .pc_ma_l_2em {
    margin-left: 2em !important;
  }
  .pc_ma_l_2rem {
    margin-left: 2rem !important;
  }
  .pc_pd_l_2em {
    padding-left: 2em !important;
  }
  .pc_pd_l_2rem {
    padding-left: 2rem !important;
  }
  .pc_ma_l_3em {
    margin-left: 3em !important;
  }
  .pc_ma_l_3rem {
    margin-left: 3rem !important;
  }
  .pc_pd_l_3em {
    padding-left: 3em !important;
  }
  .pc_pd_l_3rem {
    padding-left: 3rem !important;
  }
  .pc_ma_l_4em {
    margin-left: 4em !important;
  }
  .pc_ma_l_4rem {
    margin-left: 4rem !important;
  }
  .pc_pd_l_4em {
    padding-left: 4em !important;
  }
  .pc_pd_l_4rem {
    padding-left: 4rem !important;
  }
  .pc_ma_l_5em {
    margin-left: 5em !important;
  }
  .pc_ma_l_5rem {
    margin-left: 5rem !important;
  }
  .pc_pd_l_5em {
    padding-left: 5em !important;
  }
  .pc_pd_l_5rem {
    padding-left: 5rem !important;
  }
  .pc_ma_l_6em {
    margin-left: 6em !important;
  }
  .pc_ma_l_6rem {
    margin-left: 6rem !important;
  }
  .pc_pd_l_6em {
    padding-left: 6em !important;
  }
  .pc_pd_l_6rem {
    padding-left: 6rem !important;
  }
  .pc_ma_l_7em {
    margin-left: 7em !important;
  }
  .pc_ma_l_7rem {
    margin-left: 7rem !important;
  }
  .pc_pd_l_7em {
    padding-left: 7em !important;
  }
  .pc_pd_l_7rem {
    padding-left: 7rem !important;
  }
  .pc_ma_l_8em {
    margin-left: 8em !important;
  }
  .pc_ma_l_8rem {
    margin-left: 8rem !important;
  }
  .pc_pd_l_8em {
    padding-left: 8em !important;
  }
  .pc_pd_l_8rem {
    padding-left: 8rem !important;
  }
  .pc_ma_l_9em {
    margin-left: 9em !important;
  }
  .pc_ma_l_9rem {
    margin-left: 9rem !important;
  }
  .pc_pd_l_9em {
    padding-left: 9em !important;
  }
  .pc_pd_l_9rem {
    padding-left: 9rem !important;
  }
  .pc_ma_l_10em {
    margin-left: 10em !important;
  }
  .pc_ma_l_10rem {
    margin-left: 10rem !important;
  }
  .pc_pd_l_10em {
    padding-left: 10em !important;
  }
  .pc_pd_l_10rem {
    padding-left: 10rem !important;
  }
  .pc_ma_rl_auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  /*　text-align　*/
  .pc_text_align_left {
    text-align: left !important;
  }
  .pc_text_align_center {
    text-align: center !important;
  }
  .pc_text_align_right {
    text-align: right !important;
  }
  /*　vertical-align　*/
  .pc_v_align_top {
    vertical-align: top !important;
  }
  .pc_v_align_middle {
    vertical-align: middle !important;
  }
  .pc_v_align_bottom {
    vertical-align: bottom !important;
  }
  /*　フォントウェイト　*/
  .pc_font_bold {
    font-weight: bold !important;
  }
  .pc_font_normal {
    font-weight: normal !important;
  }
  /*　フォントサイズ　*/
  .pc_fontsize_10px {
    font-size: 10px !important;
  }
  .pc_fontsize_12px {
    font-size: 12px !important;
  }
  .pc_fontsize_14px {
    font-size: 14px !important;
  }
  .pc_fontsize_18px {
    font-size: 18px !important;
  }
  .pc_fontsize_16px {
    font-size: 16px !important;
  }
  .pc_fontsize_20px {
    font-size: 20px !important;
  }
  .pc_fontsize_21px {
    font-size: 21px !important;
  }
  .pc_fontsize_24px {
    font-size: 24px !important;
  }
  .pc_fontsize_0_75rem {
    font-size: 0.75rem !important;
  }
  .pc_fontsize_1rem {
    font-size: 1rem !important;
  }
  .pc_fontsize_1_25rem {
    font-size: 1.25rem !important;
  }
  .pc_fontsize_1_5rem {
    font-size: 1.5rem !important;
  }
  .pc_fontsize_1_75rem {
    font-size: 1.75rem !important;
  }
  .pc_fontsize_2rem {
    font-size: 2rem !important;
  }
  /*　インデント　*/
  .pc_text_indent_10px {
    text-indent: 10px !important;
  }
  .pc_text_indent_15px {
    text-indent: 15px !important;
  }
  .pc_text_indent_20px {
    text-indent: 20px !important;
  }
  .pc_text_indent_-15px {
    text-indent: -15px !important;
  }
  .pc_text_indent_-20px {
    text-indent: -20px !important;
  }
  .pc_text_indent_-30px {
    text-indent: -30px !important;
  }
  /*　ラインヘイト　*/
  .pc_line_height_0 {
    line-height: 0 !important;
  }
  .pc_line_height_16 {
    line-height: 16px !important;
  }
  .pc_line_height_18 {
    line-height: 18px !important;
  }
  .pc_line_height_24 {
    line-height: 24px !important;
  }
  .pc_line_height_32 {
    line-height: 32px !important;
  }
  /*リスト*/
  .pc_list_style_none {
    list-style: none !important;
  }
  /*　横幅　*/
  .pc_width_auto {
    width: auto !important;
  }
  .pc_width_10pa {
    width: 10% !important;
  }
  .pc_width_1em {
    width: 1em !important;
  }
  .pc_width_20pa {
    width: 20% !important;
  }
  .pc_width_2em {
    width: 2em !important;
  }
  .pc_width_30pa {
    width: 30% !important;
  }
  .pc_width_3em {
    width: 3em !important;
  }
  .pc_width_40pa {
    width: 40% !important;
  }
  .pc_width_4em {
    width: 4em !important;
  }
  .pc_width_50pa {
    width: 50% !important;
  }
  .pc_width_5em {
    width: 5em !important;
  }
  .pc_width_60pa {
    width: 60% !important;
  }
  .pc_width_6em {
    width: 6em !important;
  }
  .pc_width_70pa {
    width: 70% !important;
  }
  .pc_width_7em {
    width: 7em !important;
  }
  .pc_width_80pa {
    width: 80% !important;
  }
  .pc_width_8em {
    width: 8em !important;
  }
  .pc_width_90pa {
    width: 90% !important;
  }
  .pc_width_9em {
    width: 9em !important;
  }
  .pc_width_100pa {
    width: 100% !important;
  }
  .pc_width_10em {
    width: 10em !important;
  }
}
